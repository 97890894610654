/**
 * Desktop first media query
 * Ordering is important because we are using max-width
 * Always put 'smaller screen bellow larger' in css styles
 */
@mixin df-screen($size) {
  /***********************
   * How to use mixin:

     & > .responsive-desktop-first {
        color: aqua;

        @include mx.df-screen(desktop) {
          color: blue;
        }

        @include mx.df-screen(tablet-landscape) {
          color: purple;
        }

        @include mx.df-screen(tablet-portrait) {
          color: green;
        }

        @include mx.df-screen(mobile) {
          color: sandybrown;
        }
    }
  ***********************/
  @if $size == large-desktop {
    @media (min-width: 1800px) {
      @content;
    }
  } @else if $size == desktop {
    @media (max-width: 1799px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media screen and (max-width: 1200px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media screen and (max-width: 600px) {
      @content;
    }
  } @else if $size == mobile {
    @media screen and (max-width: 450px) {
      @content;
    }
  } @else if $size == mobile-small {
    @media screen and (max-width: 380px) {
      @content;
    }
  }
}

/**
 * Mobile first media query
 */
@mixin mf-screen($size) {
  /***********************
   * How to use mixin:

   & > .responsive-mobile-first {
      color: orangered;

      @include mx.mf-screen(tablet-portrait) {
        color: green;
      }

      @include mx.mf-screen(tablet-landscape) {
        color: purple;
      }

      @include mx.mf-screen(desktop) {
        color: yellow;
      }

      @include mx.mf-screen(large-desktop) {
        color: black;
      }
   }
  ***********************/
  @if $size == mobile {
    @media (min-width: 280px) {
      @content;
    }
  } @else if $size == mobile-landscape {
    @media (min-width: 650px) and (min-height: 375px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 1100px) {
      @content;
    }
  } @else if $size == large-desktop {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@mixin flex-center-x() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-y() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-start-x() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin user-pointer() {
  cursor: pointer;
  user-select: none;
}

@mixin disabled() {
  cursor: not-allowed;
  color: rgba(16, 16, 16, 0.3);
  border-color: rgba(118, 118, 118, 0.3);
}

@mixin default-font($weight, $size, $color: #5E5E5E) {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: $color;
}

@mixin add-required-indicator($selector) {
  #{$selector}:after {
    content: " *";
    color: red;
  }
}

@mixin html-view() {
  &::ng-deep p {
    margin-block: 0.5rem;
    min-height: 8px;
  }

  &::ng-deep ul {
    margin-block-start: 0.5rem;
  }

  &::ng-deep ol {
    margin-block-start: 0.5rem;
  }

  &::ng-deep h1 {
    font-family: 'Bebas Neue', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 110%;
    margin-block-start: 1rem;
    margin-block-end: 0.5rem;
  }

  &::ng-deep h2 {
    font-family: 'Bebas Neue', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    margin-block-start: 1rem;
    margin-block-end: 0.5rem;
  }

  &::ng-deep h3 {
    font-family: 'Bebas Neue', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    margin-block-start: 1rem;
    margin-block-end: 0.5rem;
  }

  &::ng-deep h4 {
    font-family: 'Bebas Neue', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 110%;
    margin-block-start: 1rem;
    margin-block-end: 0.5rem;
  }
}
