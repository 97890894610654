@import "shared-root";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overscroll-behavior: none;
}

:root {
  --size-nav-top-height: 5rem;
}

.wh-header-h2 {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
}

.wh-header-h3 {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
}

.wh-header-h4 {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
}

.wh-header-h5 {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
}

.wh-header-lg {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
}

.wh-header-lg {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
  text-align: left;
}

.wh-header-md {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
}

.wh-header-sm {
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;
  color: #000000;
  margin-block-end: 1rem;
}

.wh-paragraph-md {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #5E5E5E;
}

//  Hide results count label on here-maps
#ngb-live {
  display: none !important;
}

.clickable {
  cursor: pointer;
}

.active-bg {
  background-color: #e1e1e1 !important;
}
